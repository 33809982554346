html {
    height: 100%;
}

body {
    background: #e9d1b5;
    padding: 0;
    text-align: center;
    font-family: Arial,Verdana,sans-serif;
    font-weight: bold;
    position: relative;
    margin: 0;
    height: 100%;
    -webkit-font-smoothing: antialiased;
}

.wrapper {
    height: 100% !important;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
}

a {
    text-decoration: none;
}

h1, h2 {
    width: 100%;
    float: left;
    font-family: Arial,Verdana,sans-serif;
}

.main {
    float: left;
    width: 100%;
    margin: 0 auto;
}

.main h1 {
    padding:150px 50px;
    float: left;
    width: 100%;
    font-size: 45px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    font-weight: 100;
    color: white;
    margin: 0;
}

header {
    position: relative;
    z-index: 10;
}

.main section .page_container {
    position: relative;
    top: 12%;
    margin: 0 auto 0;
    z-index: 3;
}

.main section  {
    overflow: hidden;
}

.main section > img {
    position: absolute;
    max-width: 100%;
    z-index: 1;
}

.main section.page1 {
    background-size: cover;
}

.main section.page1 h1 {
    padding: 0;
    margin-bottom: 15px;
    font-size: 50px;
    color: black;
    text-align: center;
}

.main section.page1 h2 {
    color: rgba(0,0,0,0.85);
    text-align: center;
    width: 435px;
    line-height: 160%;
}

.main section.page2 {
    background-size: cover;
}

.main section.page2 > img {
    position: absolute;
    top: -300px;
    left: 50%;
    margin-left: -1095px;
}

.main section.page2 .page_container {
    overflow: hidden;
    height: 100%;
    max-width: 1400px;
    width: 660px;
}

.main section.page2 h1 {
    text-align: left;
    padding: 0;
    margin-bottom: 0;
    font-size: 70px;
    letter-spacing: -1px;
    color: black;
}

.main section.page2 h2 {
    color: rgba(0,0,0,0.85);
    text-align: center;
    line-height: 160%;
    font-weight: 100;
}

.main section.page-product {
    background-size: cover;
}

.main section.page-product > img {
    position: absolute;
    top: -300px;
    left: 50%;
    margin-left: -1095px;
}

.main section.page-product .page_container {
    overflow: hidden;
    height: 100%;
    max-width: 1400px;
    width: 770px;
}

.main section.page-product h1 {
    text-align: left;
    padding: 0;
    margin-bottom: 0;
    font-size: 70px;
    letter-spacing: -1px;
    color: black;
}

.main section.page-product h2 {
    color: rgba(0,0,0,0.85);
    text-align: center;
    line-height: 160%;
    font-weight: 100;
}

.viewing-page-2 .back{
    background: rgba(0, 0, 0, 0.25);
    color: #FFF;
}

.main section.page3 {
    background-size: cover;
}

.main section.page3 .page_container {
    overflow: hidden;
    height: 100%;
    max-width: 1400px;
    width: 660px;
}

.main section.page3 h1 {
    text-align: left;
    padding: 0;
    margin-bottom: 0;
    font-size: 70px;
    letter-spacing: -1px;
    color: black;
}

.main section.page3 h2 {
    color: rgba(0,0,0,0.85);
    text-align: left;
    line-height: 160%;
    font-weight: 100;
    font-size: 21px;
}

.main section.page4 {
    background-color: #FFFFFF;
    background-size: cover;
}

.main section.page4 .page_container {
    overflow: hidden;
    height: 100%;
    max-width: 1400px;
    width: 660px;
}

.main section.page4 h1 {
    text-align: left;
    padding: 0;
    margin-bottom: 0;
    font-size: 70px;
    letter-spacing: -1px;
    color: black;
}

.main section.page4 h2 {
    color: rgba(0,0,0,0.85);
    text-align: left;
    line-height: 160%;
    font-weight: 100;
    font-size: 21px;
}

td{
    text-align: left;
}

textarea {
    resize: none;
}

#logo > img{
    position: fixed;
    z-index: 2000;
    top: 10px;
    left: 25px;
    width: 275px;
}

.input_text{
    background: transparent url("../images/input_bg.png") no-repeat scroll 0% 0%;
    border: medium none;
    width: 340px;
    height: 41px;
    padding: 0px 15px;
}

.input_textfield{
    background: transparent url("../images/textarea_bg.png") no-repeat scroll 0% 0%;
    height: 101px;
    resize: none;
    width: 340px;
    font-size: 16px;
    padding: 10px 15px;
    overflow: auto;
    border: medium none;
}

.input_submit{
    background: transparent url("../images/send_button.png") no-repeat scroll 3px 1px;
    height: 36px;
    width: 102px;
    cursor: pointer;
    font-weight: normal;
    font-family: Arial,Verdana,sans-serif;
    font-size: 14px;
    color: #868585;
    border: medium none;
}

.input_submit:hover{
    background: transparent url("../images/send_button_hover.png") no-repeat scroll 3px 1px;
    height: 39px;
    width: 109px;
    color: #fff;
}

#contact-text{
    font-family: Arial,Verdana,sans-serif;
    text-align: left;
    font-size: 12px;
}

#nl-icon{
    background: transparent url("../images/NL.png") no-repeat scroll 0% 0%;
    width: 26px;
    height: 26px;
    cursor: pointer;
}

#nl-icon:hover{
    background: transparent url("../images/NL-MO.png") no-repeat scroll 0% 0%;
}

#en-icon{
    background: transparent url("../images/UK.png") no-repeat scroll 0% 0%;
    width: 26px;
    height: 26px;
    cursor: pointer;
}

#en-icon:hover{
    background: transparent url("../images/UK-MO.png") no-repeat scroll 0% 0%;
}

#fr-icon{
    background: transparent url("../images/FR.png") no-repeat scroll 0% 0%;
    width: 26px;
    height: 26px;
    cursor: pointer;
}

#fr-icon:hover{
    background: transparent url("../images/FR-MO.png") no-repeat scroll 0% 0%;
}

#langChanger{
    height: 26px;
    width: 26px;
    display: block;
    position: fixed;
    right: 2%;
    top: 2%;
    z-index: 100;
}

#intro{
    color: #000;
    width: 900px;
    margin: 0 auto;
    display: block;
    padding: 10px;
}

.left{
    text-align: left;
}

#right{
    float: right;
    margin-right: 20px;
}

#contact{
    margin: 0 auto;
    width: 1500px;
    display: block;
    padding: 10px;
}

#icon-1{
    cursor: pointer;
}

#icon-2{
    cursor: pointer;
    display: none;
}

.menu {
    margin-top: 2%;
    text-align: center;
    z-index: 1000;
}

.menu-item {
    padding-right: 25px;
    padding-left: 25px;
}

.menu-item a {
    cursor: pointer;
}

#top-bar{
    width: 70%;
    height: 78px;
    z-index: 1000;
    left: 15%;
    position: fixed;
}

a {
    transition: color .1s;
    color: black;

    &.link-extra {
        text-decoration: underline;
        cursor: pointer;
    }
}

a:hover {
    color: white;
}

.recipes {
    margin: 0 auto;
    width: 68%;
    height: auto;
}
.recipe{
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 225px;
    height: 265px;
    float: left;
}
.recipe:after {
    content: '';
    display: table;
    clear: both;
}
.recipe .product{
    font-size: 10px;
}

.page-current{
    color: white;
}

td, th {
    text-align: left;
}

#search {
    margin-top: 20px;
    margin-bottom: 20px;

    text-align: left;
}
